<template>
<div class="container-fluid">
    <div class="row page-titles mx-0">
        <div class="col-sm-6 p-md-0">
            <div class="welcome-text">
                <h4>Your Profile</h4>
                <!-- <p class="mb-0">Your Profile</p> -->
            </div>
        </div>
    </div>
    <!-- row -->
    <div class="row">
        <div class="col-lg-12">
            <div class="profile card card-body px-3 pt-3 pb-0">
                <div class="profile-head">
                    <div class="photo-content">
                        <div class="cover-photo" style="border-radius:5px"></div>
                    </div>
                    <div class="profile-info">
                        <div class="profile-photo">
                            <label for="file-input">
                                <img :src="profile" class="img-fluid rounded-circle img-thumbnail img-responsive" alt="">
                                <a><span class="fa fa-upload fa-3x"></span></a>
                            </label>
                            <input id="file-input" type="file" accept="image/*" @change="handleImage" style="display:none">
                        </div>
                        <div class="profile-details">
                            <div class="profile-name px-3 pt-2">
                                <h4 class="text-muted mb-0">{{first_name}} {{last_name}}</h4>
                                <h4 class="text-muted mb-0">{{email}}</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-xl-8">
            <div class="card">
                <div class="card-body">
                    <div class="profile-tab">
                        <div class="custom-tab-1">
                            <div class="tab-content">
                                <div id="profile-settings">
                                    <div class="pt-3">
                                        <div class="settings-form">
                                            <h4 class="text-primary">Account Setting</h4>
                                            <form @submit.prevent="handleSubmit">
                                                <div class="form-row">
                                                    <div class="form-group col-md-6">
                                                        <label>Email</label>
                                                        <input type="email" :placeholder="email" disabled class="form-control">
                                                    </div>
                                                </div>
                                                <div class="form-row">
                                                    <div class="form-group col-md-6">
                                                        <label>First Name</label>
                                                        <input type="text" :placeholder="first_name" disabled class="form-control">
                                                    </div>
                                                    <div class="form-group col-md-6">
                                                        <label>Last Name</label>
                                                        <input type="text" :placeholder="last_name" disabled class="form-control">
                                                    </div>
                                                </div>
                                                <div class="form-row">
                                                    <div class="form-group col-md-6">
                                                        <label>Current password</label>
                                                        <input type="password" class="form-control" v-model="current_password">
                                                    </div>
                                                    <div class="form-group col-md-6">
                                                        <label>New Password</label>
                                                        <input type="password" class="form-control" v-model="new_password">
                                                    </div>
                                                </div>
                                                <button class="btn btn-primary" type="submit">Update Profile</button>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-4">
            <div class="card">
                <div class="card-body">
                    <h5 class="text-primary d-inline">Your Valid QR Code</h5>
                    <div class="profile-blog mb-5">
                        <img :src="qrcode" width="400" height="400" style="margin:50px">
                    </div>
                    <!-- <b-button size="sm" variant="outline-info" v-b-modal.modal-sign>
                        <b-icon icon="plus-circle-fill" aria-hidden="true" class="mr-2" animation="fade"></b-icon> Sign here
                    </b-button> -->
                </div>
            </div>
        </div>
    </div>
    <b-modal id="modal-sign" size="lg" title="Sign it" @ok="save" button-size="sm">
        <div class="container">
            <div class="row">
                <div class="col-12 mt-2">
                    <VueSignaturePad id="signature" width="100%" height="500px" ref="signaturePad" :options="{onBegin: () => {$refs.signaturePad.resizeCanvas()}}" />
                </div>
                <div class="col text-center">
                    <button class="btn btn-outline-secondary" @click="undo">
                        Undo
                    </button>
                </div>
                <!-- <div class="col-6 mt-2">
                    <button class="btn btn-outline-primary float-left" @click="save">
                        Save
                    </button>
                </div> -->
            </div>
        </div>
    </b-modal>
</div>
</template>

<script>
import axios from 'axios';
import "bootstrap-vue/dist/bootstrap-vue.css";

export default {
    name: "UpdateProfile",
    metaInfo: {
        title: 'Solusi Dental',
        titleTemplate: 'Profile | %s',
    },
    // computed: {
    //     getPhotoProfile() {
    //         return this.$store.getters.stateUser.profile
    //     },
    //     getFirstname() {
    //         return this.$store.getters.stateUser.first_name
    //     },
    //     getLastname() {
    //         return this.$store.getters.stateUser.last_name
    //     },
    //     getEmail() {
    //         return this.$store.getters.stateUser.email
    //     },
    // },
    async mounted() {
        await this.getData();
        await this.getSignature();
    },
    data() {
        return {
            profile: "",
            first_name: "",
            last_name: "",
            email: "",
            current_password: "",
            new_password: "",
            signature: "",
            currentsignature: "",
            filename: "",
            newProfilePicture: "",
            qrcode: "",
        };
    },
    methods: {
        // resizeCanvas() {
        //     this.$nextTick(function () {
        //         this.$refs.signaturePad.resizeCanvas();
        //     })
        // },
        undo() {
            this.$refs.signaturePad.undoSignature();
        },
        save() {
            const {
                data
                // } = this.signaturesave(data);
            } = this.$refs.signaturePad.saveSignature();

            this.signature = data;
            this.signatureSave()
        },
        disablesignature() {
            this.$refs.signaturePad.lockSignaturePad();
        },
        async getData() {
            try {
                await this.$store.dispatch('setLoading', true);
                let resp = await axios.get("user-service/auth/profile", {
                    headers: {
                        Authorization: localStorage.getItem("token") || "",
                    },
                });

                if (resp.data.error === false) {
                    this.email = resp.data.data.email;
                    this.first_name = resp.data.data.first_name;
                    this.last_name = resp.data.data.last_name;
                    this.currentsignature = resp.data.data.signature_url;
                    this.profile = resp.data.data.profile;
                    this.$store.dispatch("updateUser", resp.data.data);
                }
            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Error", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Request ", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            } finally {
                this.$store.dispatch('setLoading', false);
            }
        },
        async getSignature() {
            try {
                    let resp = await axios.get("/user-service/auth/qrcode", {
                    headers: {
                        Authorization: localStorage.getItem("token") || "",
                    },
                });

                if (resp.data.error === false) {
                    this.qrcode = resp.data.data.image;
                }
            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Error", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Request ", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");
            }
        },
        async handleSubmit() {
            try {
                const resp = await axios.post(
                    "user-service/auth/change-password", {
                        current_password: this.current_password,
                        new_password: this.new_password,
                    }, {
                        headers: {
                            Authorization: localStorage.getItem("token") || "",
                        },
                    }
                );

                if (resp.data.error == false) {
                    this.$swal.fire("Success", resp.data.message, "success");
                    this.getData();
                    return;
                }
                this.$swal.fire("Error", resp.data.message, "error");
            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Error", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Error", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");

            } finally {
                this.isSubmit = false;
            }
        },
        async signatureSave() {
            try {
                const resp = await axios.post(
                    "user-service/auth/signature", {
                        content: this.signature,
                    }, {
                        headers: {
                            Authorization: localStorage.getItem("token") || "",
                        },
                    }
                );

                if (resp.data.error == false) {
                    this.$swal.fire("Success", resp.data.message, "success");
                    this.getData();
                    return;
                }
                this.$swal.fire("Error", resp.data.message, "error");
            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Error", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Error", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");

            }
        },
        async profileSubmit() {
            try {
                const resp = await axios.post(
                    "user-service/auth/update-photo", {
                        filename: this.filename,
                        content: this.newProfilePicture,
                    }, {
                        headers: {
                            Authorization: localStorage.getItem("token") || "",
                        },
                    }
                );

                if (resp.data.error == false) {
                    this.$swal.fire("Success", resp.data.message, "success");
                    this.getData();
                    return;
                }
                this.$swal.fire("Error", resp.data.message, "error");
            } catch (error) {
                if (error.response != undefined) {
                    if (error.response.data != undefined) {
                        if (error.response.data.error_auth != undefined) {
                            this.$swal.fire("Error", error.response.data.message, "error");
                            this.$store.dispatch("logOut").then(() => {
                                this.$router.push("/login");
                            });
                            return;
                        }
                        return this.$swal.fire("Error", error.response.data.message, "error");
                    }
                }

                this.$sentry.captureException(error);
                this.$swal.fire("Error", error.message, "error");

            }
        },
        handleImage(e) {
            const selectedImage = e.target.files[0];
            this.createBase64Image(selectedImage);
        },
        createBase64Image(fileObject) {
            const reader = new FileReader();
            reader.fileName = fileObject.name

            reader.onload = (e) => {
                this.newProfilePicture = e.target.result;
                this.filename = fileObject.name;
                this.profileSubmit();
            };
            reader.readAsDataURL(fileObject);
        }
    },
}
</script>

<style>
.profile-photo img:hover {
    opacity: 0.5
}

.profile-photo:hover a {
    opacity: 1;
    top: 0;
    z-index: 500;
}

.profile-photo:hover a span {
    top: 50%;
    position: absolute;
    left: 0;
    right: 0;
    transform: translateY(-50%);
}

.profile-photo a {
    display: block;
    position: absolute;
    top: -100%;
    opacity: 0;
    left: 0;
    bottom: 0;
    right: 0;
    text-align: center;
    color: inherit;
}
</style>
